<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>视频列表</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="toolsbar">
        <div style="float: left;margin-left: 20px;margin-top: 10px;">
          <a-button type="primary" @click="addvideo()">新建视频</a-button>
          <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">
            刷新
          </a-button>

        </div>
        <div class="serach_input">
         服务器空间已使用: {{file_ize_sum}}Gb /{{totla_capacity}}Gb<br/>
          <a-progress stroke-linecap="square" :percent="((file_ize_sum/totla_capacity)*100).toFixed(2)" />
        </div>

      </div>

      <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
        <!--                                {{data}}-->

        <a-table
            bordered
            :columns="columns"
            :dataSource="videodata"
            :pagination="pagination"
            :loading="loading"
            @change="onChange"
            size="small"
            rowKey="id"
        >
          <template slot="file_size" slot-scope="text, index">
            {{(text/1024).toFixed(2)}} Mb
          </template>
          <template slot="created_at" slot-scope="text, index">
            {{ $moment(text).fromNow() }}
          </template>
          <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="viewModal(index)">查看</a>
                            </span> <span style="margin-right: 5px;">
                              <a @click="showModal(index)">编辑</a>
                            </span>
            <span>
                              <a @click="deleModal(index)">剔除</a>
                            </span>
          </template>

        </a-table>
      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal title="查看内容（暂时不正常）" v-model="popview" width="65%" :footer="null" :destroyOnClose="true">
<!--      {{players}}-->

<!--      {{medialink}}-->
      <h1>{{players.title}}</h1>
      <small>{{ $moment(players.created_at).format("YYYY-MM-DD HH:mm:ss") }}</small>

      <div class="video-content">
        <!--        {{server_url1+src}}-->
        <video
            style="width:100%;height:100%"
            :src="medialink"
            ref="myVideo"
            preload:auto
            class="swiper-slide-img"
            id="myVideo" controls controlslist="nodownload  noremoteplayback"
            oncontextmenu = "return false">
          您的浏览器不支持 video 标签。
          <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/English.vtt" srclang="en" label="English" kind="subtitles" default  />-->
          <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Indonesian.vtt" srclang="en" label="印度语" kind="subtitles" />-->
          <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Thai.vtt" srclang="en" label="泰文" kind="subtitles" />-->
          <!--          <track v-if="mediasub=true" src="//www.psychomotricite.org/media/vtt/Vietnamese.vtt" srclang="zh-cn" label="简体中文" kind="subtitles" />-->
        </video>
<!--        <img-->
<!--            src="@/assets/playbtn.png"-->
<!--            alt=""-->
<!--            @click="operateVideo"-->
<!--            class="pointer operate-btn"-->
<!--            :class="{ 'fade-out': videoState }"-->
<!--            v-show="videoState"-->
<!--        />-->
      </div>
      <a-textarea
          v-model="copymedialink"
          :auto-size="{ minRows: 2, maxRows: 5 }"
      />
<!--      <iframe width="640" height="480" :src="'/PlayerVideo?medialink='+players.video_code" title="psychomotricite player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="playesvideo"></iframe>-->
    </a-modal>
    <a-modal title="新增视频" v-model="popadd" @ok="addok" width="65%">
      <a-form-item label="视频标题" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input v-model="add.title" placeholder="请输入视频标题"/>
      </a-form-item>
      <a-form-item label="上传视频" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">

<!--        <a-progress :percent='videostate' :status="videoactive" />-->
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/MP4'"
            :before-upload="beforeUploadvideo"
            :fileList="videolist"
            @change="handleChange_video"


        >
          <a-button  :disabled="showVideo"> <a-icon type="upload" />点击上传视频</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="中文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            :before-upload="beforeUploaditem"
            :fileList="vtt_cn"
            @change="handleChange_vtt_cn"

        >
          <a-button   :disabled="showvttcn"> <a-icon type="upload" />点击上传字幕文件——中文 </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="英文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            @change="handleChange_vtt_en"
            :before-upload="beforeUploaditem"
            :fileList="vtt_en"

        >
          <a-button  :disabled="showvtten"> <a-icon type="upload" /> 点击上传字幕文件——英文</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="法文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            @change="handleChange_vtt_fr"
            :before-upload="beforeUploaditem"
            :fileList="vtt_fr"

        >
          <a-button  :disabled="showvttfr"> <a-icon type="upload" />点击上传字幕文件——法文 </a-button>
        </a-upload>
      </a-form-item>

<!--      <a-form-item label="视频播放代码" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">-->
<!--        <div class="codes">-->
<!--        {{htmlfirst}}{{server_url}}{{add.url}}{{htmlend}}-->

<!--       </div>-->
<!--&lt;!&ndash;        <a-input v-model="add.video_code" placeholder="请输入视频名字"/>&ndash;&gt;-->
<!--      </a-form-item>-->

      <a-form-item label="视频备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-textarea
            v-model="add.remark"
            placeholder=""
            :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
    </a-modal>
    <a-modal title="编辑视频" v-model="popinfos" @ok="editok" width="65%">
      <a-form-item label="视频标题" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input v-model="edit.title" placeholder="请输入视频标题"/>
      </a-form-item>
      <a-form-item label="上传视频" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">

<!--        <a-progress :percent='videostate' :status="videoactive" />-->
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/MP4'"
            :before-upload="beforeUploadvideo"
            :fileList="videolist"
            @change="handleChange_video"


        >
          <a-button  :disabled="showVideo"> <a-icon type="upload" />点击上传视频</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="中文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            :before-upload="beforeUploaditem"
            :fileList="vtt_cn"
            @change="handleChange_vtt_cn"

        >
          <a-button   :disabled="showvttcn"> <a-icon type="upload" />点击上传字幕文件——中文 </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="英文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            @change="handleChange_vtt_en"
            :before-upload="beforeUploaditem"
            :fileList="vtt_en"

        >
          <a-button  :disabled="showvtten"> <a-icon type="upload" /> 点击上传字幕文件——英文</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="法文字幕" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" help="">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/video/vvt'"
            @change="handleChange_vtt_fr"
            :before-upload="beforeUploaditem"
            :fileList="vtt_fr"

        >
          <a-button  :disabled="showvttfr"> <a-icon type="upload" />点击上传字幕文件——法文 </a-button>
        </a-upload>
      </a-form-item>

<!--      <a-form-item label="视频播放代码" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">-->
<!--        <div class="codes">-->
<!--        {{htmlfirst}}{{server_url}}{{add.url}}{{htmlend}}-->

<!--       </div>-->
<!--&lt;!&ndash;        <a-input v-model="add.video_code" placeholder="请输入视频名字"/>&ndash;&gt;-->
<!--      </a-form-item>-->

      <a-form-item label="视频备注" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-textarea
            v-model="edit.remark"
            placeholder=""
            :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
    </a-modal>
  </a-layout>

</template>

<script>
import Config from "@/config";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: "5%",
    scopedSlots: {customRender: "id"}
  },
  {
    title: "code",
    dataIndex: "video_code",
    width: "20%",
    scopedSlots: {customRender: "video_code"}
  },{
    title: "code",
    dataIndex: "file_size",
    width: "150px",
    align: "center",
    scopedSlots: {customRender: "file_size"}
  },
  {
    title: "标题",
    dataIndex: "title",

    scopedSlots: {customRender: "title"}
  },{
    title: "备注",
    dataIndex: "remark",
    scopedSlots: {customRender: "remark"}
  },


  {
    title: "上传时间",
    dataIndex: "created_at",
    width: "90px",
    scopedSlots: {customRender: "created_at"}
  },

  {
    title: '操作',
    key: 'operation',
    width: "130px",
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
// import moment from "moment";
// import * as Util from "../common/util";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      columns,
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      video_url: Config.data.video_url,
      www_url: Config.data.www_url,
      totla_capacity: Config.data.totla_capacity,
      file_ize_sum: "",
      headers: {
        authorization: 'authorization-text',
      },
      fileList: [],
      previewImage: '',
      cover_img: '',
      videodata: [],
      videolist: [],
      vtt_cn: [],
      vtt_en: [],
      vtt_fr: [],
      players: '',
      htmlfirst:"<iframe width=\"640\" height=\"480\" src=\"https://www.psychomotricite.org/PlayerVideo?medialink=",
      htmlend:"\" title=\"WODPMTWEBADMIN Player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>",
      pagination: {
        pageSize: 20
      },
      popinfos: false,
      videoactive: 'normal',
      medialink: '',
      copymedialink: '',
      videoOptions: {
        autoplay: true,

        controls: true,
        sources: [
          {
            src:"",
            type: "video/mp4"
          }
        ]
      },
      videostate: 0,
      popview: false,
      popadd: false,
      search_text: "",
      loading: false,
      showVideo: false,
      showvttcn: false,
      showvtten: false,
      showvttfr: false,
      add: {
        title: "",
        cover_image: "",
        subtitles: {
          cn: "",
          en: "",
          fr: "",
        },
        url: "",
        video_code: "null",
        remark: "",
      },
      edit: {
        id: "",
        title: "",
        cover_image: "",
        subtitles: "",
        url: "",
        video_code: "",
        remark: "",
      },
    };
  },
  created() {
    // 获取第一页内容
    this.doSearch(1, this.pagination.pageSize);
  },
  methods: {
    // handleChange(info) {
    //   if (info.file.status !== 'uploading') {
    //     console.log(info.file, info.fileList);
    //     // console.log(language);
    //   }
    //   if (info.file.status === 'done') {
    //     this.$message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === 'error') {
    //     this.$message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
    beforeUploadvideo(file) {
      console.log("filetype", file.type);
      console.log("filesize", file.size / 1024 / 1024);
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      // if (!isJpgOrPng) {
      //   this.$message.error('You can only upload JPG file!');
      // }
      const isvideo = file.type === 'video/mp4';
      if (!isvideo) {
        this.$message.error('只能接收mp4格式的视频文件!');
      }
      const isLt2M = file.size / 1024 / 1024 < 600;
      if (!isLt2M) {
        this.$message.error('不能上传大于600M的视频!');
      }
      return isvideo && isLt2M;
    },
    beforeUploaditem(file) {
      console.log("filetype", file);
      console.log("filetype", file.type);
      console.log("filesize", file.size / 1024 / 1024);
      var index= file.name.lastIndexOf(".");
      var ext = file.name.substr(index+1);

      const isJpgOrPng = ext === 'vtt' || ext === 'str';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload vtt or str file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleChange_video(info) {

      console.log("info", info);
      console.log("status", info.file.status);
      this.videolist = info.fileList;
      if (info.file.status === "uploading" ){
        this.videoactive = "active";
        this.showVideo = true;
        // console.log("event",info.event);
        const event = info.event
        if (event) { // 一定要加判断，不然会报错
          let percent = Math.floor((event.loaded / event.total) * 100)

          this.videostate = percent
          console.log("上传进度",this.videostate) // percent就是进度条的数值
        }
      }
      if (info.file.status === "done") {
        this.videoactive = "success";
        this.showVideo = true;
        this.add.url = info.file.response.src;
        this.edit.url = info.file.response.src;
      }
      if (info.file.status === "removed") {
        this.videoactive = "normal";
        this.showVideo = false;
        this.add.url = "";
        this.edit.url = "";
      }
      // console.log(this.edit_Article.cover_image);



    },
    handleChange_vtt_cn(info) {

      console.log("info", info);
      // console.log("lang", lang);
      console.log(info.file.status);
      this.vtt_cn = info.fileList;
      if (info.file.status === "uploading" ) {
        this.showvttcn = true;
      }
      if (info.file.status === "done" ) {
        this.showvttcn = true;
        this.add.subtitles.cn = info.file.response.src;
        this.edit.subtitles.cn = info.file.response.src;
      }

      if (info.file.status === "removed") {
        this.showvttcn = false;
      }
      // console.log(this.edit_Article.cover_image);
    },
    handleChange_vtt_en(info) {

      console.log("info", info);
      // console.log("lang", lang);
      console.log(info.file.status);
      this.vtt_en = info.fileList;
      if (info.file.status === "uploading" ) {
        this.showvtten = true;
      }
      if (info.file.status === "done" ) {
        this.showvtten = true;
        this.add.subtitles.en = info.file.response.src;
        this.edit.subtitles.en = info.file.response.src;
      }

      if (info.file.status === "removed") {
        this.showvtten = false;
      }
      // console.log(this.edit_Article.cover_image);
    },
    handleChange_vtt_fr(info) {

      console.log("info", info);
      // console.log("lang", lang);
      console.log(info.file.status);
      this.vtt_fr = info.fileList;
      if (info.file.status === "uploading" ) {
        this.showvttfr = true;
      }
      if (info.file.status === "done" ) {
        this.showvttfr = true;
        this.add.subtitles.fr = info.file.response.src;
        this.edit.subtitles.fr = info.file.response.src;
      }

      if (info.file.status === "removed") {
        this.showvttfr = false;
      }
      // console.log(this.edit_Article.cover_image);
    },
    handleChange(info) {

      console.log("info", info);
      // console.log("lang", lang);
      console.log(info.file.status);
      this.fileList = info.fileList;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.edit_Article.cover_image = this.fileList[i].response.src;
        }
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      // console.log(this.edit_Article.cover_image);
    },
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize);
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, text);
    },

    async onChange(pagination) {
      await this.doSearch(
          pagination.current,
          pagination.pageSize
      );
    },

    async onChanges(e) {
      console.log(e.target.value);
    },

    showModal(item) {
      console.log(item)
      this.edit.id = item.id;
      this.showVideo = true;
      this.showvttcn = true;
      this.showvtten = true;
      this.showvttfr = true;
      this.getinfo();
      this.popinfos = true;
    },
    viewModal(item) {
      console.log(item)
      this.players=item
      this.mediainfo(item.video_code)
      this.popview = true;

    },
    addvideo() {
      this.add.title = "";
      this.add.cover_image = "";
      this.add.subtitles = {
        cn: "",
        en: "",
        fr: "",
      };
      this.add.url = "";
      this.add.video_code =  "";
      this.add.remark =  "";
      this.videolist= [];
          this.vtt_cn= [];
          this.vtt_en= [];
          this.vtt_fr= [];
      this.popadd = true;
    },

    async addok() {


      if (this.add.title ==="") {
        Modal.warning({
          title: "请输入视频标题"
        });
        this.popinfos = true;
        return
      }
      if (this.add.url ==="") {
        Modal.warning({
          title: "请上传视频后提交"
        });
        this.popinfos = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/videos/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          title: this.add.title,
          cover_image: this.add.cover_image,
          url: this.add.url,
          subtitles: this.add.subtitles,
          video_code: this.add.video_code,
          remark: this.add.remark,
          t: now,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.doSearch(1, this.pagination.pageSize, "");
        Modal.info({
          title: "创建成功"
        });
      }
      this.popadd = false;
    },
    async editok() {


      if (this.edit.title ==="") {
        Modal.warning({
          title: "请输入视频标题"
        });
        this.popinfos = true;
        return
      }
      if (this.edit.url ==="") {
        Modal.warning({
          title: "请上传视频后提交"
        });
        this.popinfos = true;
        return
      }

      let now = Date.now();
      let result = await this.$post("/api/admin/videos/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.edit.id,
        save_value: {
          title: this.edit.title,
          cover_image: this.edit.cover_image,
          url: this.edit.url,
          subtitles: this.edit.subtitles,
          remark: this.edit.remark,
          t: now,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.doSearch(1, this.pagination.pageSize, "");
        Modal.info({
          title: "修改成功"
        });
      }
      this.popinfos = false;
    },
    async doSearch(page, limit) {
      this.loading = true;
      this.data = [];
      console.log(Storage.uid)
      console.log(Storage.token)
      let now = Date.now();
      let result = await this.$get("/api/admin/videos/list", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          page: page,
          limit: limit,
          t: now,
        }
      });

      if (result.status === true) {
        // let data = [...this.data];
        // for (let i in result.data.list) {
        //   let info = result.data.list[i];
        //   let offset = (parseInt(page) - 1) * limit + parseInt(i);
        //   // console.log(offset)
        //
        //   data[offset] = {
        //     uid: info.uid,
        //     name: info.name,
        //     group_name: info.group_name,
        //     login_ip: info.login_ip,
        //     login_name: info.login_name,
        //     login_time: moment(info.login_time * 1000).format("YYYY/MM/DD"),
        //     // login_time: info.login_time,
        //     mobile: info.mobile,
        //     nike_name: info.nike_name,
        //     sex: info.sex,
        //     status: info.status,
        //   };
        // }

        let pagination = {...this.pagination};
        pagination.total = result.data.total;
        this.pagination = pagination;
        this.videodata = result.data.list;
        this.file_ize_sum = ((result.data.file_ize_sum/1024)/1024).toFixed(2);
        this.loading = false;
      }

      this.loading = false;
    },
    async getinfo() {
      this.loading = true;
      let now = Date.now();
      let result = await this.$get("/api/admin/videos/info", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          id: this.edit.id,
          t: now,
        }
      });

      if (result.status === true) {
        console.log(result.data);

        this.edit.title = result.data.title;
        this.edit.remark = result.data.remark;
        this.edit.video_code = result.data.video_code;
        this.edit.subtitles = result.data.subtitles;
        this.edit.url = result.data.url;
        this.videolist=[{
          uid: 0,
          name: result.data.url,
          status: 'done',
          url: this.server_url + result.data.url,
          response: {
            data: result.data.url
          }
        }];
        this.vtt_cn=[{
          uid: 0,
          name: result.data.subtitles.cn,
          status: 'done',
          url: this.server_url + result.data.subtitles.cn,
          response: {
            data: result.data.subtitles.cn
          }
        }];
        this.vtt_en=[{
          uid: 0,
          name: result.data.subtitles.en,
          status: 'done',
          url: this.server_url + result.data.subtitles.en,
          response: {
            data: result.data.subtitles.en
          }
        }];
        this.vtt_fr=[{
          uid: 0,
          name: result.data.subtitles.fr,
          status: 'done',
          url: this.server_url + result.data.subtitles.fr,
          response: {
            data: result.data.subtitles.fr
          }
        }]
        this.loading = false;
      }

      this.loading = false;
    },
    async deleModal(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该视频?" ,

        onOk: async function () {

          let result = await that.$post("/api/admin/videos/delete", {

            uid: Storage.uid,
            token: Storage.token,
            id: item.id,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            that.data = []
            await that.doSearch(1, that.pagination.pageSize, "");

            Modal.info({
              title: "剔除成功"
            });
          }
          if(result.status === false){
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
    async mediainfo(code) {
      console.log("请求code", code);
      this.loading = true;
      let result = await this.$get("/api/customer/videos/play", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          video_code: code,
        }
      });
      this.medialink=this.video_url+result
      this.copymedialink="<iframe width=\"640\" height=\"480\" src=\""+this.www_url+'/PlayerVideo?medialink='+this.players.video_code+"\" title=\"ISPMTA player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>"
      console.log("geturl" ,result);

      this.loading = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 45vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
  .left{width:30%}
  .right{width:60%;display: inline-block}
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
.codes{
  padding: 10px;
  border: 1px dotted #1890ff;
}
/deep/ .ant-modal-body .playesvideo{
    text-align: center;
  }
</style>
